<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.ID }}</td>
                            <td>{{ item.Username }}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.PayType, payservice_typelist)}">{{ item.PayType | display_value(payservice_typelist) }}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.Status, payservice_statuslist)}">{{ item.Status | display_value(payservice_statuslist) }}</td>
                            <td class="text-end">{{ item.Amount | currency('¥', 1)}}</td>
                            <td>{{ item.CreatedDate | display_date }}</td>
                            <td class="text-center">
                                <v-icon medium class="mr-2" @click="gotoDetail(item.ID)">fa-edit</v-icon>
                                <v-icon v-bind:style="{ 'visibility': item.Status == 3 || item.Status == 4 ? 'visible' : 'hidden'}" medium class="mr-2" @click="payItem(item)">fa-credit-card</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
    <v-dialog v-model="dialogNew" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="title">Tạo mới đơn hàng</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md pa-0>
            <v-layout row wrap>
              <v-flex lg8 md8 sm12>
                <v-autocomplete label="Khách hàng" v-model="newOrder.UID" :items="customers" item-text="Username" item-value="ID" ></v-autocomplete>
              </v-flex>
              <v-flex lg8 md8 sm12>
                <h-currency-input v-model="newOrder.ExchangeRate" :decimal="0" label="Tỷ giá (₫)"></h-currency-input>
              </v-flex>
              <v-flex lg8 md8 sm12>
                <h-currency-input v-model="newOrder.Amount" :decimal="1" label="Số tiền thanh toán hộ (¥)"></h-currency-input>
              </v-flex>
              <v-flex lg8 md8 sm12>
                <h-currency-input v-model="newOrder.Fee" :decimal="1" label="Phí thanh toán (¥)"></h-currency-input>
              </v-flex>
              <v-flex lg8 md8 sm12>
                <h-currency-input v-model="newOrder.GuaranteeRate" :decimal="1" label="Phí bảo hiểm hàng hoá (%)"></h-currency-input>
              </v-flex>
              <v-flex lg8 md8 sm12>
                <v-text-field v-model="newOrder.BankName" label="Ngân hàng người nhận"></v-text-field>
              </v-flex>
              <v-flex lg8 md8 sm12>
                <v-text-field v-model="newOrder.BankAccountName" label="Họ tên người nhận"></v-text-field>
              </v-flex>
              <v-flex lg8 md8 sm12>
                <v-text-field v-model="newOrder.BankAccountNumber" label="Tài khoản người nhận"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 white--text" @click="dialogNew = false">Đóng</v-btn>
          <v-btn color="red darken-1 white--text" @click="createNewOrder">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import { sendErrorNotice, sendSuccessNotice } from "@/commons/utils";
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            headers: [
                {
                    text: "ID",
                    value: "ID",                   
                    dataType: dataType["Number"]
                },
                {
                    text: "Khách hàng",
                    value: "Username",
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Loại đơn",
                    value: "PayType",
                    filterable: true,
                    values: configs.PAY_SERVICE_TYPE,
                    dataType: dataType["Enum"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    values: configs.PAY_SERVICE_STATUS,
                    dataType: dataType["Enum"]
                },
                {
                    text: "Số tiền",
                    value: "Amount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Ngày tạo",
                    value: "CreatedDate",
                    filterable: true,
                    dataType: dataType["Date"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            payservice_statuslist: configs.PAY_SERVICE_STATUS,
            payserviceStatusEnum: configs.PAY_SERVICE_STATUS_ENUM,
            payservice_typelist: configs.PAY_SERVICE_TYPE,
            viewName: "payservice_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
            dialogNew: false,
            newOrder: {
                UID: 0,
                ExchangeRate: 0,
                Status: 3,
            }
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: mapState({
        items: state => state.payservice.all.data,
        total_rows: state => state.payservice.all.total,
        loading: state => state.payservice.loading,
        customers: state => state.customer.master.data
    }),
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("payservice/getList", param);
            this.getCustomerList();
        },
        payItem(payItem) {
            let request = {
                Status: 5,
                UID: payItem.UID
            };
            if(payItem.Status == 3) {
                request.Payment = payItem.TotalAmount;
            }
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("payservice/setDetail", {
                data: request,
                id: payItem.ID,
                params: param
            });
        },
        gotoDetail: function (id) {
            this.$router.push({
                name: "PayServiceDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết (#${id})`
                }
            });
        },
        deleteItem: function(id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        getCustomerList() {
            this.$store.dispatch("customer/getCustomerList", {
                is_master: 1,
                fields: ["ID", "Username"]
            });
        },
        createData() {
            this.dialogNew = true;
        },
        createNewOrder() {
            if(this.newOrder.UID == 0) {
                sendErrorNotice("Hãy chọn khách hàng!");
                return;
            }
            const cust = _.find(this.customers, {'ID': this.newOrder.UID});
            this.newOrder.Username = cust.Username;
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("payservice/setDetail", {data: this.newOrder, id: 0, params: param});
            this.dialogNew = false;
            this.newOrder = {
                UID: 0,
                Status: 3
            };
            sendSuccessNotice('Tạo mới đơn hàng thành công.');
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("payservice/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
